<template>
  <div class="banner">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item 
        ><img src="../assets/image/ban1.jpg" alt="" srcset=""
      /></van-swipe-item>
      <van-swipe-item 
        ><img src="../assets/image/ban2.jpg" alt="" srcset=""
      /></van-swipe-item>
      <van-swipe-item 
        ><img src="../assets/image/ban3.jpg" alt="" srcset=""
      /></van-swipe-item>
      <van-swipe-item
        ><img src="../assets/image/ban4.jpg" alt="" srcset=""
      /></van-swipe-item>
      <van-swipe-item
        ><img src="../assets/image/ban5.jpg" alt="" srcset=""
      /></van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>

export default {
  name: "Home",
  components: {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 180px;
  overflow: hidden;
  .van-swipe-item {
    width: 100%;
    height: 180px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
