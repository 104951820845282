<template>
  <div class="home" ref="myRef">
    <swipe-content />
    <div class="list">
      <div class="item" @click="navigetDetail('home')">
        <div class="item_box">
          <span class="item_span"
            ><img src="../assets/image/icon/about.png"
          /></span>
          <div class="item_title">关于我们</div>
        </div>
        <van-icon name="arrow" size="26" color="#ccc" />
      </div>
      <div class="item" @click="navigetDetail('store')">
        <div class="item_box">
          <span class="item_span"
            ><img src="../assets/image/icon/store.png"
          /></span>
          <div class="item_title">全国门店</div>
        </div>
        <van-icon name="arrow" size="26" color="#ccc" />
      </div>
      <div class="item" @click="navigetDetail('visit')">
        <div class="item_box">
          <span class="item_span"
            ><img src="../assets/image/icon/visit.png"
          /></span>
          <div class="item_title">体验预约</div>
        </div>
        <van-icon name="arrow" size="26" color="#ccc" />
      </div>
      <div class="item" @click="navigetDetail('game')">
        <div class="item_box">
          <span class="item_span"
            ><img src="../assets/image/icon/game.png"
          /></span>
          <div class="item_title">游戏专区</div>
        </div>
        <van-icon name="arrow" size="26" color="#ccc" />
      </div>
    </div>
  </div>
</template>
<script>
// import mapbar from "../components/map";
import { reactive, onMounted, toRefs, ref } from "vue";
import swipeContent from "../components/swipe.vue";
import { useRouter } from "vue-router";
export default {
  name: "About",
  components: {
    swipeContent,
  },
  setup() {
    const router = useRouter();
    const myRef = ref(null);
    onMounted(() => {
      console.log("33333333333", myRef.value.style);
      myRef.value.style.height =
        `${document.documentElement.clientHeight}` + "px"; //动态设置HTML元素高度
    });
    const state = reactive({
      aboutList: [],
    });
    const navigetDetail = (val) => {
      if (val === "home") {
        router.push({ path: "about" });
      } else if (val === "store") {
        router.push({ path: "shop" });
      } else if (val === "visit") {
        router.push({ path: "visit" });
      } else if (val === "game") {
        router.push({ path: "gameView" });
      }
    };
    return {
      ...toRefs(state),
      myRef,
      navigetDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: auto;
  overflow: hidden;
  .list {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;
      overflow: hidden;
      background: #ffffff;
      box-shadow: 0px 7px 18px 0px rgba(4, 0, 0, 0.08);
      border-radius: 5px;
      padding: 16px 17px 16px 23px;
      box-sizing: border-box;
      margin-bottom: 15px;
      .item_box {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .item_span {
        width: 43px;
        height: 43px;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item_title {
        font-size: 18px;
        font-weight: 900;
        color: #333333;
        margin-left: 25px;
      }
    }
  }
}
</style>
